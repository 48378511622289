<template>
    <div class="view">
        <div class="ac-loading"><van-loading type="spinner" color="#666" /></div>
        <van-row type="flex" justify="space-between">
            <van-col span="24" class="title">
                <div class="right">
                    <img src="../../../assets/images/visitortitle.png" />
                </div>
                <div class="left">
                    <img src="../../../assets/images/visitorlogo.png" />
                </div>
            </van-col>
        </van-row>
        <van-row type="flex" justify="center">
            <van-col span="24">
                <div class="detail">
                    <van-form ref="form" @submit="onGenPass" label-align="right">
                        <van-field v-model="formData.communityId" name="小区" label="小区" class="select">
                            <template #input>
                                <select name="area" v-model="formData.communityId" @change="communityChange" class="select">
                                    <option v-for="(item, index) in communityData" :key="index" :value="item.id">{{ item.name }}</option>
                                </select>
                            </template>
                        </van-field>
                        <van-field
                            v-model="formData.visitorName"
                            name="mobile"
                            label="访客姓名"
                            type="text"
                            placeholder="请输入访客姓名"
                            :rules="[{ required: true, message: '请输入访客姓名' }]"
                        />
                        <van-field name="radio" label="性别" class="noborder">
                            <template #input>
                                <van-radio-group v-model="formData.sex" direction="horizontal" checked-color="#832D79">
                                    <van-radio name="1">男</van-radio>
                                    <van-radio name="2">女</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>
                        <van-field
                            v-model="formData.visitorPhone"
                            name="mobile"
                            label="手机号码"
                            type="tel"
                            placeholder="请输入访客手机号码"
                            :rules="[
                                { required: true, message: '请输入访客手机号码' },
                                { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误' },
                            ]"
                        />
                        <van-field name="radio" label="驾车来访" class="noborder">
                            <template #input>
                                <van-radio-group v-model="formData.isDriving" direction="horizontal" checked-color="#832D79">
                                    <van-radio name="1">是</van-radio>
                                    <van-radio name="2">否</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>
                        <van-field name="radio" label="授权类型" class="noborder">
                            <template #input>
                                <van-radio-group v-model="formData.authType" direction="horizontal" checked-color="#832D79">
                                    <van-radio name="1">次数</van-radio>
                                    <van-radio name="2">一天</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>
                        <!-- #442676 -->
                        <van-field
                            v-if="formData.authType == 1"
                            class="noborder2"
                            name="count"
                            label="授权次数"
                            button-size="25px"
                            placeholder="请输入次数"
                            :rules="[{ required: true, message: '请输入授权次数' }]"
                        >
                            <template #input>
                                <van-stepper v-model="formData.count" min="1" max="10" />
                            </template>
                        </van-field>
                        <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="formData.visitorTime"
                            label="到访时间"
                            placeholder="点击选择到访时间"
                            @click="showVisitorTime = true"
                        />
                        <van-calendar v-model="showVisitorTime" color="#442676" @confirm="onVisitorTimeConfirm" />
                        <van-field v-model="formData.visitorType" name="访客类别" label="访客类别" class="select">
                            <template #input>
                                <select>
                                    <option value="family">家人</option>
                                    <option value="visitor">访客</option>
                                    <option value="friends">朋友</option>
                                    <option value="courier">快递</option>
                                    <option value="hourly-employee">钟点工</option>
                                    <option value="other">其他</option>
                                </select>
                            </template>
                        </van-field>
                        <div class="oper">
                            <van-button round block type="info" native-type="submit" />
                            <van-button round block type="info" native-type="button" @click="onSendSms" />
                        </div>
                    </van-form>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { Col, Row, Field, Button, Form, Radio, RadioGroup, Stepper, Calendar, Dialog, Loading } from 'vant';

    export default {
        components: {
            [Col.name]: Col,
            [Row.name]: Row,
            [Field.name]: Field,
            [Button.name]: Button,
            [Form.name]: Form,
            [Radio.name]: Radio,
            [RadioGroup.name]: RadioGroup,
            [Stepper.name]: Stepper,
            [Calendar.name]: Calendar,
            [Dialog.name]: Dialog,
            [Loading.name]: Loading,
        },
        data() {
            return {
                showVisitorTime: false,
                currentCommunity: null,
                communityData: [],
                formData: {
                    communityId: null,
                    visitorName: null,
                    sex: '1',
                    isDriving: '1',
                    authType: '1',
                    count: 1,
                    visitorType: 'family',
                    visitorPhone: null,
                    visitorTime: this.$common.formatDate(new Date(), 'YYYY-MM-DD'),
                },
                inParams: {
                    tid: null, // (小区Id)
                    tname: null, // 小区名称
                    dong: null, // (栋号，4位内数字）
                    ho: null, // (房号，4位内数字）
                    ownerPhone: null, //（业主手机号）
                    ownerName: null, //（微信名）
                },
            };
        },
        computed: {
            maxDate() {
                let d = new Date();
                d.setDate(d.getDate() + 1);
                return d;
            },
        },
        methods: {
            onGenPass() {
                let that = this;
                Dialog.confirm({
                    title: '',
                    message: '确认生成通行证？',
                })
                    .then(() => {
                        that.genQrCodeData((shortUrlCode) => {
                            that.$router.replace({
                                path: `/t/${shortUrlCode}`,
                            });
                        });
                    })
                    .catch(() => {});
            },
            onSendSms() {
                let that = this;
                this.$refs.form.validate().then(() => {
                    Dialog.confirm({
                        title: '',
                        message: '确认发送短信？',
                    })
                        .then(() => {
                            that.genQrCodeData((shortUrlCode) => {
                                that.sendSms(that.formData.visitorPhone, shortUrlCode, (shortUrlCode) => {
                                    that.$router.replace({
                                        path: `/t/${shortUrlCode}`,
                                        query: {
                                            type: that.formData.authType,
                                        },
                                    });
                                });
                            });
                        })
                        .catch(() => {});
                });
            },
            onVisitorTimeConfirm(date) {
                this.formData.visitorTime = this.$common.formatDate(date, 'YYYY-MM-DD');
                this.showVisitorTime = false;
            },
            communityChange() {
                this.inParams.tname = this.getCommunityName(this.formData.communityId);
            },
            getCommunityName() {
                if (!this.$common.isEmptyObject(this.communityData)) {
                    try {
                        return this.communityData.filter((element) => {
                            return element.id == this.formData.communityId;
                        })[0].name;
                    } catch (e) {
                        return '-';
                    }
                }
                return '-';
            },
            genQrCodeData(callback) {
                let that = this;
                that.$common.showLoading();
                let now = new Date();
                let params = {
                    community: this.formData.communityId,
                    communityName: this.inParams.tname,
                    dong: this.inParams.dong,
                    ho: this.inParams.ho,
                    ownerName: this.inParams.ownerName,
                    ownerPhone: this.inParams.ownerPhone,
                    role: 3,
                    createTime: this.$common.formatDate(now, 'YYYY-MM-DD HH:mm:ss'),
                    visitorPhone: this.formData.visitorPhone,
                    visitorName: this.formData.visitorName,
                    visitorType: this.formData.visitorType,
                    sex: this.formData.visitorType,
                    isDriving: this.formData.isDriving,
                };
                if (this.formData.authType == 1) {
                    params.count = this.formData.count;
                } else {
                    let vdate = this.$common.formatDate(this.formData.visitorTime, 'YYYY-MM-DD');
                    params.startTime = vdate + ' 00:00:00';
                    params.endTime = vdate + ' 23:59:59';
                }
                that.request({
                    params: { method: 'post', ...params },
                    url: '/qrcode',
                    callback(code, data) {
                        if (data.code == 200) {
                            callback(data.result.shortUrlCode);
                        } else {
                            that.$toast('生成二维码失败');
                        }
                        that.$common.hideLoading();
                    },
                    error() {
                        that.$toast('生成二维码失败');
                        that.$common.hideLoading();
                    },
                });
            },
            sendSms(phone, shortUrlCode, callback) {
                let that = this;
                let params = { phone: phone, shortUrlCode: shortUrlCode };
                that.request({
                    params: params,
                    url: '/person/sms/send',
                    callback(code, data) {
                        console.log(code, data);
                        if (data.code == 200) {
                            callback(shortUrlCode);
                        } else {
                            that.$toast('发送短信失败');
                        }
                        that.$common.hideLoading();
                    },
                    error() {
                        that.$toast('发送短信失败');
                        that.$common.hideLoading();
                    },
                });
            },
            initCommunityData() {
                let that = this;
                that.request({
                    params: { mobile: this.inParams.ownerPhone },
                    url: '/community/list',
                    callback(code, data) {
                        console.log(code, data);
                        that.communityData = data.result;
                        // 设置小区名称
                        that.inParams.tname = that.getCommunityName(that.inParams.tid);
                    },
                    error() {},
                });
            },
            ...mapActions(['request']),
        },
        created() {
            this.inParams.tid = this.$route.query.tid;
            this.inParams.tname = this.$route.query.tname;
            this.inParams.dong = this.$route.query.dong;
            this.inParams.ho = this.$route.query.ho;
            this.inParams.ownerPhone = this.$route.query.ownerPhone;
            this.inParams.ownerName = this.$route.query.ownerName;
            // 默认选中跳转参数
            this.formData.communityId = this.inParams.tid;
        },
        mounted() {
            this.initCommunityData();
        },
    };
</script>
<style lang="less" scoped>
    @import '../../../styles/core.less';
    .view {
        background: url('../../../assets/images/visitorbg.png');
        background-size: 100% 100%;
        position: relative;
    }
    .title {
        padding: 40px 20px 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title .right img {
        width: 150px;
    }
    .title .left img {
        width: 50px;
    }
    .detail {
        margin: 0 10px 10px 10px;
        padding: 10px;
        background: url('../../../assets/images/visitorbg2.png');
        background-size: 100% 100%;
    }
    .detail .van-cell {
        background: transparent;
        padding-right: 25px;
    }
    .detail .van-cell::after {
        border-bottom: none;
    }
    .detail .van-cell /deep/.van-cell__title {
        color: #fff;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-right: 25px;
        font-size: 12px;
        font-weight: bold;
    }
    .detail /deep/.van-field__control {
        display: block;
        width: 100%;
        height: 32px;
        padding: 6px 12px;
        font-size: 12px;
        line-height: 1.42857143;
        color: white;
        background-color: #442676;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    .detail .select /deep/.van-field__control {
        padding: 0 10px;
    }
    .detail .select select {
        background: transparent;
        background-image: none;
        color: white;
        width: 100%;
        height: 30px;
        outline: none;
        border: none;
        text-transform: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .detail .select select:focus {
        outline: none;
    }

    // .detail .select::after {
    //     content: '';
    //     position: absolute;
    //     width: 0px;
    //     height: 0px;
    //     top: 50%;
    //     right: 8px;
    //     margin-top: -4px;
    //     border: 8px solid #929497;
    //     border-width: 8px 5px 8px;
    //     border-color: #929497 transparent transparent transparent;
    //     z-index: 2;
    //     pointer-events: none;
    // }
    .detail /deep/ .van-stepper .van-stepper__minus,
    .detail /deep/ .van-stepper .van-stepper__plus {
        background: #442676;
        color: #fff;
    }
    .detail /deep/ .van-stepper .van-stepper__minus--disabled,
    .detail /deep/ .van-stepper .van-stepper__plus--disabled {
        color: #624c86;
    }
    .detail /deep/ .van-stepper input {
        border: 1px solid #ccc;
        background: #442676;
        color: #fff;
        border-radius: 5px;
    }
    .detail .noborder /deep/.van-field__control {
        border: none;
        background: transparent;
        padding: 6px 0;
    }
    .detail .noborder2 /deep/.van-field__control {
        border: none;
        background: transparent;
        padding: 3px 0;
    }
    .detail /deep/.van-radio__label {
        color: #fff;
    }
    .detail .oper {
        display: flex;
        justify-content: space-around;
        padding: 10px 0;
    }
    .detail .oper button {
        border: none;
        width: 120px;
        height: 40px;
    }
    .detail .oper button:first-child {
        background: url('../../../assets/images/btnpass.png');
        background-size: 100% 100%;
    }
    .detail .oper button:last-child {
        background: url('../../../assets/images/btnsms.png');
        background-size: 100% 100%;
    }
</style>
